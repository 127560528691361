import AttachDisk from '@/layouts/Stack/components/AttachDisk.vue';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/fetch';
import Vue from 'vue';

export default {
  mixins: [showErrorModal, storeMixin],
  computed: {
    projectId() {
      return this.$store.getters['moduleStack/projectId'];
    },
    id() {
      return this.tariff.id;
    },
  },
  methods: {
    attachDisk: function (instance, disk) {
      const that = this;
      return new Promise(() => {
        const selfName = 'AttachDisk';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: AttachDisk,
          closeOnBackdrop: false,
          props: {
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            instance: data => {
              instance = data;
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  // console.log(this.instance);
                  this.attachDiskToInstance(instance, disk)
                    .then(data => {
                      if (data.status === 200) {
                        this.$modals.close({ name: selfName });
                        // const payload = { project: this.projectId, disk: this.id };
                        const payload = { disk: this.id };
                        this.$store
                          .dispatch('moduleStack/checkDiskStatus', payload)
                          .then(async data => {
                            const res = await data.status;
                            // console.log(res);
                            if (res !== 'in-use') {
                              return this.updateDiskStatusAttach(_, {
                                project: this.projectId,
                                disk: this.id,
                                type: 'volumes',
                                silent: true,
                              });
                            }
                          })
                          .catch(e => this.showError(e));
                        this.newFetch();
                        this.showResModal('Диск успешно подключен.');
                      }
                    })
                    .catch(e => {
                      console.log(e);
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async updateDiskStatusAttach(callback, payload) {
      this.timerCnt = 1;
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/checkDiskStatus', {
            disk: this.id,
            type: 'volumes',
            silent: true,
          })
          .then(async data => {
            if (!['in-use'].includes(data.status)) {
              this.res = data.status;
              await this.updateDiskStatusAttach(callback, payload);
            } else {
              this.timerCnt = 1;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = data.status;
              return 'success';
            }
          });
      }, 1000 * this.timerCnt);
    },
    attachDiskToInstance(instance, disk) {
      const params = {
        instance: instance,
        disk: disk,
      };
      return this.$store.dispatch('moduleStack/attachDisk', params);
      // .catch(e => {
      // this.showError(e);
      // });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
